import './media-block.scss';
import Swiper, { EffectFade, Navigation, Pagination, Thumbs } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/effect-fade';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/thumbs';

class MediaBlock {
    constructor ($mediaBlock) {
        this.$mediaBlock = $mediaBlock;
        this.$slider = this.$mediaBlock.querySelector('[data-media-block="slider"]');
        this.$counter = this.$mediaBlock.querySelector('[data-media-block="counter"]');
        this.$thumbPagination = this.$mediaBlock.querySelector('[data-media-block="thumb-pagination"]');
    }

    init () {
        if (this.$slider) {
            this.initSlider();
            this.setActiveThumbSlide();
        }
    }

    initSlider () {
        // thumb slider
        const $prevButton = this.$mediaBlock.querySelector('[data-media-block="prev"]');
        const $nextButton = this.$mediaBlock.querySelector('[data-media-block="next"]');
        this.thumbPagination = new Swiper(this.$thumbPagination, {
            modules: [Navigation],
            slidesPerView: 'auto',
            navigation: {
                prevEl: $prevButton,
                nextEl: $nextButton
            },
            resistanceRatio: 0,
            preventClicks: false
        });

        // main slider
        const $pagination = this.$mediaBlock.querySelector('[data-media-block="dot-pagination"]');
        const $mobilePrevButton = this.$mediaBlock.querySelector('[data-media-block="mobile-prev"]');
        const $mobileNextButton = this.$mediaBlock.querySelector('[data-media-block="mobile-next"]');

        this.slider = new Swiper(this.$slider, {
            modules: [Navigation, Pagination, EffectFade, Thumbs],
            thumbs: {
                swiper: this.thumbPagination
            },
            navigation: {
                prevEl: $mobilePrevButton,
                nextEl: $mobileNextButton
            },
            pagination: {
                el: $pagination,
                clickable: true
            },
            resistanceRatio: 0,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            }
        });

        this.slider.on('slideChange', () => {
            this.setActiveThumbSlide();
            this.updateCounter();
        });

        this.slider.on('resize', () => {
            this.updateCounter();
        });

        this.updateCounter();
    }

    updateCounter () {
        if (this.$counter) {
            this.$counter.textContent = `${this.slider.activeIndex + 1} / ${this.slider.slides.length}`;
        }
    }

    setActiveThumbSlide () {
        const activeIndex = this.slider.activeIndex;
        const $thumbSlides = this.thumbPagination.slides;

        if (!$thumbSlides) {
            return false;
        }
        for (let i = 0; i < $thumbSlides.length; i++) {
            const $currentSlide = $thumbSlides[i];

            if (activeIndex === i) {
                $currentSlide.classList.add('media-block__thumb-slide--active');
            } else {
                $currentSlide.classList.remove('media-block__thumb-slide--active');
            }
        }
    }
}

export { MediaBlock };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $mediaBlock = $context.querySelectorAll('[data-media-block="root"]');

        for (let i = 0; i < $mediaBlock.length; i++) {
            const $$mediaBlock = new MediaBlock($mediaBlock[i]);
            $$mediaBlock.init();
        }
    }
});
